header {
    min-height: 60px;
    background-color: #393e46;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.nav{
    color: white;
}
.nav div{
    transition: all 0.3s ease;
    padding: 1rem 2rem;
    height: 60px;
}
.nav div:hover{
    cursor: pointer;
    text-decoration: underline;
    background-color: #0d2a33;
}