:root {
  --primary-color: hsl(196, 78%, 61%);
}
.loginContainer {
  padding: 2rem 0;
}
.error{
  font-size: .75rem;
  color: red;
}
.login-form {
  margin: auto;
  width: 60%;
  max-width: 400px;
  min-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 40px;
  color: white;
  font-family: "Microsoft YaHei", serif;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  box-shadow: 0 0.4px 0.4px rgba(128, 128, 128, 0.109), 0 1px 1px rgba(128, 128, 128, 0.155),
    0 2.1px 2.1px rgba(128, 128, 128, 0.195), 0 4.4px 4.4px rgba(128, 128, 128, 0.241),
    0 12px 12px rgba(128, 128, 128, 0.35);
}
.login-form h1 {
  margin: 0 0 24px 0;
}
.login-form a {
  color: white;
}
.login-form input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-text-fill-color: white;
  -webkit-box-shadow: 0 0 0px 1000px #00000000 inset;
  transition: background-color 5000s ease-in-out 0s;
}
.login-form .form-input-material {
  margin: 12px 0;
}
.login-form .btn {
  width: 100%;
  margin: 18px 0 9px 0;
  padding: 8px 20px;
  position: relative;
  border-radius: 0;
}
.login-form input {
  color: white;
}
.login-form button,
.login-form input {
  font: inherit;
  outline: none;
}

.form-input-material {
  --input-border-bottom-color: white;
  position: relative;
  border-bottom: 1px solid var(--input-border-bottom-color);
}
.form-input-material::before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -1px;
  width: 100%;
  height: 2px;
  background-color: var(--primary-color);
  transform: scaleX(0);
  transform-origin: center;
  transition: 0.5s;
}
.form-input-material:focus-within::before {
  transform: scaleX(1);
}
.form-input-material .form-control-material {
  padding: 0.5rem 0;
  background: none;
  border: none;
}
.form-input-material .form-control-material:focus ~ label,
.form-input-material .form-control-material:not(:placeholder-shown) ~ label {
  transform: translateY(-110%) scale(0.8);
  color: var(--primary-color);
}
.form-input-material label {
  position: absolute;
  top: 0.5rem;
  left: 0;
  transition: 0.3s;
  transform-origin: left;
}

.btn-ghost {
  --btn-color: var(--primary-color);
  --btn-border-color: var(--primary-color);
  background: none;
  transition: 0.3s;
  overflow: hidden;
  color: var(--btn-color);
  border: 1px solid var(--btn-border-color);
}
.btn-ghost::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(120deg, transparent, var(--primary-color), transparent);
  transform: translateX(-100%);
  transition: 0.6s;
}
.btn-ghost:hover {
  box-shadow: 0 0 20px 5px rgba(51, 152, 219, 0.5);
}
.btn-ghost:hover::before {
  transform: translateX(100%);
}
